import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaArrowUp } from 'react-icons/fa';
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

export default function BlogPost({ data }) {
  const post = data.allWpPost.edges[0].node
  const image = getImage(post.featuredImage.node.localFile)
  const metaDescriptionWithTags = post.excerpt
  const formattedMetaDescription = metaDescriptionWithTags.replace(/(<([^>]+)>)/gi, "");
  const slug = post.slug
  const totalslug = "https://www.bartsalle.nl/" + slug;
  /* console.log(post) */
  return (
    <Layout>
      <Helmet>
        <title>{post.title}</title>
        <link rel="canonical" href={totalslug} />
        <meta name="description" content={formattedMetaDescription} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={formattedMetaDescription} />
        <meta property="og:url" content={totalslug} />
        <meta property="og:site_name" content="Bart Sallé, freelance webdeveloper" />
        <body className="blogpost" />
      </Helmet>
      <div className="container singleblog">
        <div className="blogleft">
          <h1>{post.title}</h1>
          <span className="blogpostdate">{post.date}</span>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
          <div className="returntoblog"><Link to="/blog/"><span>Bekijk alle blogposts</span><FaArrowUp size={16} /></Link></div>
        </div>
        <div className="blogright">
          <GatsbyImage image={image} />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          excerpt
          slug
          date(formatString: "D-MM-YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1200)
                }
              }
            }
          }
        }
      }
    }
  }
`